import { FeaturePage } from "../components/FeaturePage";
import React from 'react'

export default () => (
  <FeaturePage
    heading1="Process, store, and report on payments and card authorisations"
    heading2="Automate payments for treatments and packages at the point of booking. Alternatively, take a card authorisation for enquiries"
    heading3="Take the stress out of payments"
    headerBlocks={[
      "Deliver a slick payment experience with a fully PCI compliant checkout process",
      "Upsell and process payment on products and packages at the point of booking",
      "Take payment with Apple Pay, Google Pay, credit/debit card, charge to hotel room, vouchers, in-person PDQ payments and more",
      "Stay on top of payments within Trybe reports",
    ]}
    featurePageBlocks={[
      {
        chip: 'Online payments',
        heading: 'Secure payments at the point of booking',
        body: [
          "For automated bookings, you’re able to take payment during the customer check-out process. Payments will flow into your designated bank account. You’ll be able to see the payment logged to the customer on arrival within Trybe.",
        ]
      },
      {
        chip: 'Payment methods',
        heading: 'Reduce the barrier to entry by accepting all forms of payment',
        body: [
          'Trybe accepts various forms of payment including Apple Pay*, Google Pay*, credit/debit card, in-person PDQ payment, charge to hotel room, payment by voucher and more.',
        ],
      },
      {
        chip: 'Upsell',
        heading: 'Tap into an additional revenue stream',
        body: [
          'Give customers a chance to select and pay for additional treatments and in-house spa products during their checkout process.',
        ],
      },
    ]}
  />
)
